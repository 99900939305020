"use strict";

export const newComplianceItemFields = {
  templateUrl: "admin/views/compliance/new-compliance-item-fields.html",
  bindings: {
    complianceInstanceModel: "=",
    isEditMode: "<",
    defaultExpirySettings: "<"
  },
  //! @ngInject
  controller: function ($scope, $rootScope, generalUtils, toaster, moment) {
    $scope.newFiles = null;

    const expiryDateOptionsMap = {
        'DAY': 'd',
        'WEEK': 'w',
        'MONTH': 'M'
    };

    const initialize = () => {
        $scope.expirationDatePickerOptions = {
            minDate: $scope.$ctrl.complianceInstanceModel.effectiveDate,
            startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek
        };

        $scope.onExpirationDateChange = (date) => {
            $scope.$ctrl.complianceInstanceModel.expiryDate = date;
            $scope.setExpirationDateText(date);
        };
    };

    const generateDateInDays = (days) => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() + days);
        return date;
    };

    const today = generateDateInDays(0);
    const getExpirationText = (date) => {
        date = new Date(
            date !== undefined
            ? date
            : $scope.$ctrl.complianceInstanceModel.expiryDate
        );
        date.setHours(0, 0, 0, 0);

        const dateButtons = $scope.$ctrl.complianceInstanceModel.expiryDateButtons;

        if (date < today) {
            return "";
        }

        if (generalUtils.isSameDayNativeDate(today, date)) {
            return "today";
        }

        for (const btn of dateButtons) {
            if (generalUtils.isSameDayNativeDate(btn.date, date)) {
                return "in " + btn.title;
            }
        }

        const daysDiff = Math.ceil(Math.abs(date - today) / (1000 * 86400));

        return `in ${daysDiff} ` + (daysDiff > 1 ? "days" : "day");
    };

    const getCalculatedExpiryDate = () => {
        if (!$scope.$ctrl.defaultExpirySettings) {
            return;
        }

        return moment($scope.$ctrl.complianceInstanceModel.effectiveDate)
            .clone()
            .add(
                $scope.$ctrl.defaultExpirySettings.expiryAmount,
                expiryDateOptionsMap[$scope.$ctrl.defaultExpirySettings.expiryUnit]
            );
    };

    $scope.updateExpirationDatePickerOptions = () => {
        $scope.expirationDatePickerOptions.minDate = $scope.$ctrl.complianceInstanceModel.effectiveDate;
        if ($scope.$ctrl.defaultExpirySettings) {
            $scope.setDefaultExpirationDateAccordingToDefault();
            $scope.setExpirationDateText($scope.$ctrl.complianceInstanceModel.expiryDate);
        }
    }

    $scope.setExpirationDateText = (date) => {
        $scope.expiryDateText = getExpirationText(date);
    };

    $scope.isExpiredAndSetToCompliant = () => {
        return $scope.$ctrl.complianceInstanceModel.isCompliant &&
                $scope.$ctrl.complianceInstanceModel.expiryDate &&
                $scope.$ctrl.complianceInstanceModel.expiryDate < new Date();
    };

    $scope.removeFile = (fileIndex) => {
        if (!$scope.$ctrl.complianceInstanceModel.files || !$scope.$ctrl.complianceInstanceModel.files[fileIndex]) {
            toaster.pop('error', 'File not found')
            return;
        }
        $scope.$ctrl.complianceInstanceModel.files.splice(fileIndex, 1);
        $scope.$ctrl.complianceInstanceModel.filesNames.splice(fileIndex, 1);
    }

    $scope.$watch('newFiles', () => {
        if (!$scope.newFiles || $scope.newFiles.length === 0) {
            $scope.newFiles = null;
            return;
        }
        const newFiles = $scope.$ctrl.complianceInstanceModel.files ? $scope.newFiles.filter(file => !$scope.$ctrl.complianceInstanceModel.files.find(f => f.name === file.name)) : $scope.newFiles;
        if (!newFiles || newFiles.length === 0) {
            $scope.newFiles = null;
            return;
        };
        const allFiles = $scope.$ctrl.complianceInstanceModel.files ? $scope.$ctrl.complianceInstanceModel.files.concat(newFiles) : newFiles;
        newFiles.forEach(file => {
            let fileType = file.name.split('.');
            fileType = fileType[fileType.length - 1];
            fileType = fileType.toLowerCase();
            if (['pdf', 'png', 'jpg', 'jpeg'].indexOf(fileType) === -1) {
                toaster.pop('warning', "Unsupported file type", '');
            }
        });
        $scope.$ctrl.complianceInstanceModel.filesNames = allFiles.map(file => file.name);
        if ($scope.$ctrl.complianceInstanceModel.files !== allFiles) $scope.$ctrl.complianceInstanceModel.files = allFiles;
        $scope.newFiles = null;
    });

    $scope.$watch('files', () => {
        if (!$scope.$ctrl.complianceInstanceModel.files || $scope.$ctrl.complianceInstanceModel.files.length === 0) {
            $scope.$ctrl.complianceInstanceModel.filesNames = [];
            return;
        }
        $scope.$ctrl.complianceInstanceModel.filesNames = $scope.$ctrl.complianceInstanceModel.files.map(file => file.name);
    });

    $scope.setDefaultExpirationDateAccordingToDefault = () => {
        const updatedExpiryDate = getCalculatedExpiryDate()?.toDate();
        $scope.$ctrl.complianceInstanceModel.expiryDate = updatedExpiryDate;
    };

    $scope.isEmptyField = (field) => {
        switch (field.type) {
            case "Text":
            case "Dropdown":
                return !Boolean(field.model);
            case "Date":
                return !Boolean(field.model.value);
            default:
                console.error(`Unhandled field type: ${field.type}`);
        }
    }

    this.$onInit = () => {
        initialize();
    };
  },
};