export type MultiselectCallbackParameter<TType> = { id: TType }[];

class MultiselectCtrl implements ng.IComponentController {
  // ---- bindings ----- //
  options!: [];
  onSelect!: () => (item: { id: unknown; label: string }[]) => void;
  // ------------------- //

  model: { id: unknown; label: string }[] = [];

  events = {
    onSelectionChanged: () => {
      this.onSelect()(this.model);
    },
  };

  extraSettings = {
    styleActive: true,
    scrollable: true,
    enableSearch: true,
  };
}

export const multiselectComponent = {
  $name: "multiselect",
  templateUrl: "admin/components/multiselect/multiselect.component.html",
  controller: MultiselectCtrl,
  controllerAs: "ctrl",
  bindings: {
    options: "<",
    onSelect: "&",
  },
};
