import { LocalDate, nativeJs, Instant } from "js-joda";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import moment from "moment";
import _ from "lodash";

export const patientMedicationProfile = {
    templateUrl: "admin/views/patient-medication-profile.html",
    bindings: {
        patient: "<",
        readonly: "<",
        showActions: "<",
        isAnswer: "<",
        questionId: "<",
        answer: "<",
        saveAnswer: "<"
    },
    //! @ngInject
    controller: function($scope, $rootScope, $http, $uibModal, Consts, base64ImageConsts, NgTableParams, DatabaseApi, toaster, SweetAlert) {
        const vm = this;
        $scope.notEqualAnswerWithProfile = false;
        $scope.medication2updateProfileCheck = {};
        const isRowShouldBeSynced = (id) => !!$scope.medication2updateProfileCheck[id];
        const isNewlyCreatedMedicationId = {};
        $scope.initCheck = (item) => {
            if ($scope.medication2updateProfileCheck[item.id] === undefined) {
                $scope.medication2updateProfileCheck[item.id] = $scope.isEqualToPatientProfile(item);
            }
        };

        function checkNotEqualAnswerWithProfile() {
            const notEqualAnswerWithProfile = !isEqualProfilesLists($scope.patientDocumentMedications, $scope.patientProfileMedications);
            $scope.notEqualAnswerWithProfile = notEqualAnswerWithProfile;
        }

        $scope.isEqualToPatientProfile = (item) => {
            if ($scope.patientProfileMedications && $scope.patientDocumentMedications) {
                if (item) {
                    return !!findEqualProfile(item, $scope.patientProfileMedications);
                }
            }

            return !$scope.isAnswer;
        };

        vm.$onInit = async function() {
            $scope.isAnswer = vm.isAnswer;
            $scope.answer = vm.answer;

            if ($scope.isAnswer) {
                $scope.patientDocumentMedications = $scope.answer;

                if ($scope.answer) {
                    initMedicationProfileTable($scope.answer);
                }
            }

            await getPatientMedicationProfile();
        };

        async function onChangeAnswer(answer) {
            answer = JSON.parse(JSON.stringify(answer || []));
            for (const item of answer) {
                if (item.from) {
                    item.from = parseDate(item.from);
                }
                if (item.until) {
                    item.until = parseDate(item.until);
                }
            }

            const instantNow = Instant.now();
            return vm.saveAnswer(vm.questionId, -1, answer, instantNow);
        }

        function createOrUpdateOnList(list, item) {
            const searchedIndex = _.findIndex(
                list, ({ id }) => id === item.id
            );

            if (searchedIndex === -1) {
                list.push(item);
            } else {
                list[searchedIndex] = item;
            }

            return list;
        }

        async function saveAnswer(item) {
            $scope.answer = $scope.answer || [];
            createOrUpdateOnList($scope.answer, item);
            $scope.patientDocumentMedications = $scope.answer;
            checkNotEqualAnswerWithProfile();
            await onChangeAnswer($scope.answer);
        }

        async function getPatientMedicationProfile() {
            const medicationsUrl = Consts.api
                + "agencies/" + $rootScope.agencyId
                + "/agency_members/" + $rootScope.agencyMemberId
                + "/patient/" + vm.patient.id + "/medication_profile";

            const { data } = await $http.get(medicationsUrl);

            $scope.patientProfileMedications = data.medications;

            if ($scope.isAnswer) {
                if (!$scope.answer) {
                    $scope.answer = JSON.parse(JSON.stringify(data.medications));
                    $scope.patientDocumentMedications = $scope.answer;
                    initMedicationProfileTable($scope.patientDocumentMedications);
                }
                checkNotEqualAnswerWithProfile();
            } else {
                initMedicationProfileTable($scope.patientProfileMedications);
            }
        }

        $scope.newMedicationProfileTable = function() {
            const newMedication = getEmptyMedicationObject();
            initMedicationProfileTable([newMedication]);
        };

        function findNextLocalId(list = []) {
            const minId = list
                .filter((code) => code.id < 0)
                .reduce((min, item) => ((item.id < min) ? item.id : min), 0);

            return minId - 1;
        }

        $scope.addNewMedication = function() {
            const newMedication = getEmptyMedicationObject();
            $scope.medication2updateProfileCheck[newMedication.id] = true;
            isNewlyCreatedMedicationId[newMedication.id] = true;
            $scope.medicationProfileTable.data.unshift(newMedication);
        };

        function getEmptyMedicationObject() {
            const id = findNextLocalId($scope.medicationProfileTable.data);
            return {
                id: id,
                patientId: vm.patient.id,
                medicationId: null,
                from: null,
                until: null,
                frequency: null,
                status: "ACTIVE",
                notes: null,
                dose: "",
                route: "",
                medicationName: ""
            };
        }

        $scope.medicationsAutoCompleteOptions = {
            minimumChars: 1,
            containerCssClass: "medicationProfileDropDown",
            data: function(searchText) {
                return DatabaseApi.get("agencies/" + $rootScope.agencyId + "/agency_members/" + $rootScope.agencyMemberId + "/medication_profile/search?value=" + searchText)
                    .then(function(response) {
                        return response.data.medications;
                    }, function(err) {
                        toaster.pop("error", "Something went wrong", "Could not get medications");
                    });
            },
            renderItem: function(item) {
                return {
                    value: item.display_name,
                    label: "<div class='medicationSearchResult'>"
                        + "<span class='medicineName'>" + item.displayName + "</span>"
                        + "<span class='medicineDoseRoute'>" + item.dose + " • " + item.route + "</span>"
                        + "</div>"
                };
            },
            itemSelected: async function(e) {
                let item = $scope.medicationProfileTable.data[$scope.currentMedicationRowIndex];
                item.medicationId = e.item.id;
                item.medicationName = e.item.displayName;
                item.dose = e.item.dose;
                item.route = e.item.route;

                await saveItem(item);
            }
        };

        async function saveItem(item) {
            const createMedicationOnPatientProfile = async (item) => {
                const createdMedication = await addMedicationToPatientProfile(item.medicationId);
                if (item.id < 0) {
                    $scope.medication2updateProfileCheck[createdMedication.id] = $scope.medication2updateProfileCheck[item.id];
                    delete $scope.medication2updateProfileCheck[item.id];
                    isNewlyCreatedMedicationId[createdMedication.id] = true;
                    delete isNewlyCreatedMedicationId[item.id];
                    item.id = createdMedication.id;
                }

                await updatePatientMedicationProfile(item);

                return item;
            };

            const saveMedicationOnPatientProfile = async (item) => {
                if (item.id) {
                    if (item.id > 0) {
                        await updatePatientMedicationProfile(item);
                    } else {
                        item = await createMedicationOnPatientProfile(item);
                    }
                } else {
                    item = await addMedicationToPatientProfile(item.medicationId);
                }

                createOrUpdateOnList($scope.patientProfileMedications, { ...item });

                return item;
            };

            if ($scope.isAnswer) {
                if (isRowShouldBeSynced(item.id)) {
                    if (item.id < 0) {
                        $scope.answer = _.filter($scope.answer, ({ id }) => item.id !== id);
                    }

                    item = await saveMedicationOnPatientProfile(item);
                }

                await saveAnswer(item);
                initMedicationProfileTable($scope.answer);
            } else {
                await saveMedicationOnPatientProfile(item);
                initMedicationProfileTable($scope.patientProfileMedications);
            }
        }

        async function addMedicationToPatientProfile(medicationId) {
            try {
                const newMedicationRowBody = { medicationId };

                const url = "agencies/" + $rootScope.agencyId
                    + "/agency_members/" + $rootScope.agencyMemberId
                    + "/patient/" + vm.patient.id
                    + "/medication_profile";

                const { data } = await DatabaseApi.post(url, newMedicationRowBody);
                reloadTable();
                toaster.pop("success", "A new medication created successfully");

                return data;
            } catch (err) {
                toaster.pop("error", "Something went wrong", "Could not create new medication");
            }
        }

        async function removeMedicationItem(item) {
            const removePatientMedicationProfile = async (updateTable = false) => {
                $scope.patientProfileMedications = _.filter(
                    $scope.patientProfileMedications, ({ id }) => item.id !== id
                );

                if (updateTable) {
                    initMedicationProfileTable($scope.patientProfileMedications);
                }
                
                await updatePatientMedicationProfile({ ...item, status: "DISCONTINUED" }).then(
                    () => toaster.pop("success", "Medication removed successfully"),
                    () => toaster.pop("error", "Something went wrong", "Could not remove medication")
                );

            };

            const removeAnswer = async (item) => {
                $scope.answer = _.filter($scope.answer, ({ id }) => item.id !== id);
                $scope.patientDocumentMedications = $scope.answer;
                initMedicationProfileTable($scope.answer);
                await onChangeAnswer($scope.answer);
            }

            if ($scope.isAnswer) {
                if (isRowShouldBeSynced(item.id) && item.id > 0) {
                    await removePatientMedicationProfile(false);
                }

                await removeAnswer(item);
            } else {
                await removePatientMedicationProfile(true);
            }

            delete $scope.medication2updateProfileCheck[item.id];
        }

        $scope.removeMedication = async function(item) {
            SweetAlert.swal({
                title: "Remove?",
                text: "Are you sure you want to remove this medication?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3077EB",
                confirmButtonText: "Yes, remove",
                closeOnConfirm: true,
                closeOnCancel: true
            }, async function(isConfirm) {
                if (isConfirm) {
                    await removeMedicationItem(item);
                }
            });
        };

        function prepareItem(item) {
            const body = { ...item };

            ["from", "until"].forEach(field => {
                if (body[field]) {
                    body[field] = stringifyDate(body[field]);
                }
            });

            return body;
        }


        $scope.updateMedicationProfileRow = async function(idx, item, field) {
            saveItem(item).then(
                () => toaster.pop("success", "Medication updated successfully"),
                (err) => {
                    console.log(`err ==== `, err);
                    toaster.pop("error", "Something went wrong", "Could not update medication");
                }
            );
        };

        async function updatePatientMedicationProfile(item) {
            const putMedicationProfileUrl = "agencies/" + $rootScope.agencyId
                + "/agency_members/" + $rootScope.agencyMemberId
                + "/patient/" + vm.patient.id
                + "/medication_profile";

            return DatabaseApi.put(putMedicationProfileUrl, prepareItem(item));
        }

        $scope.setRelevantMedicationRow = function(rowIndex) {
            $scope.currentMedicationRowIndex = rowIndex;
        };

        function sortBubblingNewMedicationToTop(medicationProfiles) {
            const newMedicationProfiles = [];
            const oldMedicationProfiles = [];

            for (const medicationProfile of medicationProfiles) {
                if (isNewlyCreatedMedicationId[medicationProfile.id]) {
                    newMedicationProfiles.push(medicationProfile);
                } else {
                    oldMedicationProfiles.push(medicationProfile);
                }
            }

            return [
                ...newMedicationProfiles.reverse(),
                ...oldMedicationProfiles
            ];
        }

        const parseDate = date => LocalDate.from(nativeJs(moment(date)));
        const stringifyDate = date => parseDate(date).toString();

        function initMedicationProfileTable(items) {
            const dataset = sortBubblingNewMedicationToTop(items.map((item) => {
                const mapped = { ...item };
                if (mapped.from !== null) {
                    const d = parseDate(mapped.from);
                    mapped.from = new Date(d.year(), d.month().value() - 1, d.dayOfMonth());
                }
                if (mapped.until !== null) {
                    const d = parseDate(mapped.until);
                    mapped.until = new Date(d.year(), d.month().value() - 1, d.dayOfMonth());
                }

                return mapped;
            }));

            reloadTable(dataset);

            if ($scope.isAnswer) {
                checkNotEqualAnswerWithProfile();
            }
        }

        function reloadTable(items) {
            $scope.medicationProfileTable = new NgTableParams({ count: 10 }, {
                counts: [],
                dataset: items ?? $scope.medicationProfileTable.data
            });
        }

        function getJSPDFCenterOffset(doc, text) {
            const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            return (doc.internal.pageSize.width - textWidth) / 2;
        }

        $scope.viewEncodeMedicationProfilePdf = (encodePDFOnly) => {
            let doc = new jsPDF();
            let patientName = vm.patient.firstName + " " + vm.patient.lastName;
            const medflytLogoBase64 = base64ImageConsts.medflytLogo;

            let cols = [{
                medicationName: "Medication Name", dose: "Dose", route: "Route",
                frequency: "Frequency", from: "From", until: "Until", notes: "Notes"
            }];
            let rows = [];
            let frequency, from, until, notes, temp;

            $scope.medicationProfileTable.data.forEach(medication => {
                frequency = medication.frequency !== null ? medication.frequency : "";
                from = medication.from !== null ? stringifyDate(medication.from) : "";
                until = medication.until !== null ? stringifyDate(medication.until) : "";
                notes = medication.notes !== null ? medication.notes : "";

                temp = [medication.medicationName, medication.dose,
                    medication.route, frequency, from, until, notes];
                rows.push(temp);
            });

            doc.setFontSize(25);
            doc.text(patientName, getJSPDFCenterOffset(doc, patientName), 40);

            doc.setFontSize(15);
            doc.setTextColor(100);
            doc.text("Medication Profile", getJSPDFCenterOffset(doc, "Medication Profile"), 47);
            autoTable(doc, {
                head: cols,
                body: rows,
                startY: 55,
                didDrawPage: function(data) {
                    doc.setFontSize(20);
                    doc.setTextColor(40);
                    doc.setFontStyle("normal");
                    doc.addImage(medflytLogoBase64, "JPEG", (doc.internal.pageSize.width / 2) - 30, 15, 60, 15);
                },
                margin: { top: 30 }
            });
            if (encodePDFOnly) {
                return doc.output("datauri");
            }
            const fileDate = stringifyDate(new Date());
            const fileName = patientName + "-Medication Profile-" + fileDate;
            doc.save(fileName);
        };

        $scope.sendMedicationProfilePdfToEmailOrFax = (type) => {
            let pdfData = $scope.viewEncodeMedicationProfilePdf(true);

            const newScope = $scope.$new();
            newScope.modalTitle = "Send Medication Profile";

            newScope.attachmentName = "Medication Profile";
            newScope.type = type;
            newScope.encodedPdf = pdfData;

            $uibModal.open({
                templateUrl: "admin/views/send-encoded-pdf-modal.html",
                size: "md",
                scope: newScope,
                controller: "sendEncodedPdfModalCtrl"
            });
        };

        $scope.openNewAgencyMedicineModal = (rowIndex) => {
            $scope.currentMedicationRowIndex = rowIndex;
            const modalInstance = $uibModal.open({
                templateUrl: "admin/views/new-agency-medicine-modal.html",
                size: "lg",
                controller: "newAgencyMedicineModalCtrl",
                resolve: {
                    patientId: () => vm.patient.id
                }
            });
            modalInstance.result.then((res) => {
                if (res.id) {
                    $scope.medicationProfileTable.data[$scope.currentMedicationRowIndex] = res;
                    reloadTable();
                }
            });
        };

        $scope.resetAnswerToProfile = async function() {
            if ($scope.patientProfileMedications) {
                const copiedList = JSON.parse(JSON.stringify($scope.patientProfileMedications));
                $scope.patientDocumentMedications = copiedList;
                $scope.answer = $scope.patientDocumentMedications;

                $scope.medication2updateProfileCheck = {};
                $scope.patientDocumentMedications.forEach(
                    ({ id }) => $scope.medication2updateProfileCheck[id] = true
                );

                initMedicationProfileTable($scope.patientDocumentMedications);

                await onChangeAnswer($scope.answer);
            }
        };

        function isEqualProfiles(profileA, profileB) {
            const fieldsToCompare = [
                "id", "medicationId", "frequency", "from", "until", "status", "notes", "dose", "route"
            ];

            const isEqualDates = (valueA, valueB) => (valueA === valueB)
                || (valueA && valueB && stringifyDate(valueA) === stringifyDate(valueB));

            for (const field of fieldsToCompare) {
                const isDateField = field === "from" || field === "until";
                const isEqualValues = isDateField
                    ? isEqualDates(profileA[field], profileB[field])
                    : profileA[field] === profileB[field];

                if (!isEqualValues) {
                    return false;
                }
            }

            return true;
        }

        function findEqualProfile(profileToFind, profiles) {
            for (const profile of (profiles || [])) {
                if (isEqualProfiles(profileToFind, profile)) {
                    return profile;
                }
            }

            return null;
        }

        function isEqualProfilesLists(profilesListA, profilesListB) {
            const areAllProfilesEqual = (listA, listB) => {
                for (const profile of (listA || [])) {
                    const sameProfile = findEqualProfile(profile, listB);
                    if (!sameProfile) {
                        return false;
                    }
                }
                return true;
            };

            return areAllProfilesEqual(profilesListA, profilesListB)
                && areAllProfilesEqual(profilesListB, profilesListA);
        }
    }
};