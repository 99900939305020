export class FilterManager<TType, TFilterOptions> {
  private filters: Map<TFilterOptions, (item: TType) => boolean> = new Map();

  constructor(private items: TType[], private onFilter?: (items: TType[]) => void) {}

  filter = () => {
    const data = this.hasFilters() ? this.items.filter((item) => {
      for (const filter of this.filters.values()) {
        if (!filter(item)) {
          return false;
        }
      }
      return true;
    }) : this.items;

    if (this.onFilter !== undefined) {
      this.onFilter(data);
    }
  };

  addFilter = (key: TFilterOptions, filter: (item: TType) => boolean) => {
    this.filters.set(key, filter);
    this.filter();
  };

  removeFilter = (key: TFilterOptions) => {
    this.filters.delete(key);
    this.filter();
  };

  clearFilters = () => {
    this.filters.clear();
    this.filter();
  };

  hasFilters = (): boolean => {
    return this.filters.size > 0;
  };

  hasFilter = (key: TFilterOptions): boolean => {
    return this.filters.has(key);
  };

  getFilters = (): TFilterOptions[] => {
    return [...this.filters.keys()];
  };
}
