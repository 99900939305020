import { LocalDate } from "js-joda";
import {
  ComplianceCaregiverRecord,
  ComplianceDocument,
  DashboardComplianceItem,
} from "../../models/compliance";
import { assertNever } from "../../scripts/consts/assertNever.const";
import { caregiverStatus } from "../../scripts/consts/commonConsts";
import { dateUtils } from "../../scripts/consts/dateUtils";
import { CaregiverDict, CaregiverStatus, CaregiverWithDisplayName } from "../../scripts/messages/caregiver";
import { ComplianceStatsResponse } from "../../scripts/messages/compliance";
import { CaregiverDocumentTypeId, CaregiverId } from "../../scripts/messages/ids";
import { CompService } from "../../scripts/services/compService";
import { DatabaseApiService } from "../../scripts/services/db";
import { FilterManager } from "../../scripts/services/filterManagerService";
import { getCaregiverStatusColor, LabelColor } from "../../scripts/utils/caregiverUtils";
import {
  getCaregiverOverviewComplianceStatusColor,
  getComplianceItemOverviewStatusColor,
  parseSectionLabel,
  sumItemsStatusGroups,
} from "../../scripts/utils/complianceUtils";
import { assertDefined, groupBy, upperCaseFirst } from "../../scripts/utils/generalUtils";
import { MultiselectCallbackParameter } from "../multiselect/multiselect.component";
import { ChartLabel, PieChartColor, PieChartEntry } from "../pie-chart/pie-chart.component";
import "./compliance-dashboard.component.scss";

type ChartName = "employmentStatus" | "complianceStatus" | "itemsComplianceStatus";

type CardName =
  | "incompliantCaregiversWithActiveCase"
  | "caregiversWithOnlyOneIncompliantItem"
  | "incompliantItemsWithUploadedDocuments"
  | "pendingApplicationCaregivers";

type CaregiverComplianceStatus = "Compliant" | "Not compliant";

interface CaregiverTableEntry extends CaregiverWithDisplayName {
  complianceStatus: CaregiverComplianceStatus;
  complianceStatusColor: "red" | "green";
}

interface ComplianceItemTableEntry extends DashboardComplianceItem {
  display: {
    text: string;
    color: LabelColor;
  };
}

interface TableEntry {
  caregiver: CaregiverTableEntry;
  items: Record<string, ComplianceItemTableEntry>;
}

interface TableColumn {
  title: string;
  section: string;
  colored: boolean;
  sort?: "asc" | "desc";
}

//! @ngInject
class ComplianceDashboardCtrl implements ng.IComponentController {
  items?: ComplianceCaregiverRecord[];
  charts: Record<ChartName, PieChartEntry[]> = {
    employmentStatus: [],
    complianceStatus: [],
    itemsComplianceStatus: [],
  };
  stats?: ComplianceStatsResponse;
  localStats: {
    pendingApplicationCaregivers: CaregiverId[];
  } = {
    pendingApplicationCaregivers: [],
  };
  table: NgTableParams<TableEntry>;
  dropdownDocumentOptions: { id: CaregiverDocumentTypeId; label: string }[] = [];
  tableDocumentsColumns: TableColumn[] = [
    { title: "Caregiver Name", section: "Caregiver Information", colored: false },
    { title: "ID", section: "Caregiver Information", colored: false },
    { title: "Compliance", section: "Caregiver Information", colored: false },
  ];
  tableDocumentsColumnsGroups: { section: string; count: number }[] = [];
  rootDocumentsRecords: Record<string, ComplianceDocument> = {};

  private selectedDocumentTypeIds: Set<CaregiverDocumentTypeId> = new Set();
  private tableDataset?: TableEntry[];
  private caregiversMap: CaregiverDict = {};

  public isLoadingItems = true;
  public isLoadingStats = true;
  public isLoadingCaregivers = true;
  public activeRowCaregiverId?: CaregiverId;
  public filters = {
    freeText: "",
    dropdowns: {
      caregiverStatus: {
        options: caregiverStatus.dropdown,
        selected: [],
      },
      complianceStatus: {
        options: ["Compliant", "Not compliant"].map((status) => ({ id: status, label: status })),
        selected: [],
      },
    },
  };
  public filterManager: FilterManager<TableEntry, string>;
  public cardsFromServerData: Partial<Record<CardName, { icon: string; title: string }>> = {
    incompliantCaregiversWithActiveCase: {
      icon: "fa-calendar",
      title: "Not compliant caregivers are active on a case",
    },
    caregiversWithOnlyOneIncompliantItem: {
      icon: "fa-bolt",
      title: "Are missing only 1 item to be compliant",
    },
    incompliantItemsWithUploadedDocuments: {
      icon: "fa-id-card",
      title: "Non-compliant items that are available on the passport",
    },
  };

  constructor(
    private compService: CompService,
    private toaster: toaster.IToasterService,
    private NgTableParams: NgTable.ITableParamsConstructor<TableEntry>,
    private $rootScope: ng.IRootScopeService,
    private $scope: ng.IScope,
    private DatabaseApi: DatabaseApiService
  ) {
    this.table = new this.NgTableParams({}, { dataset: [] });
    this.filterManager = new FilterManager<TableEntry, string>([]);
    this.fetchCaregivers();
  }

  $onInit = () => {
    this.getStats();
    this.$rootScope.$on("got_caregivers_data", () => this.fetchCaregivers());
  };

  getItems = () => {
    this.compService
      .fetch(this.caregiversMap)
      .then((data) => {
        this.dropdownDocumentOptions = data.documents.map((document) => ({
          id: document.id,
          label: document.name,
        }));
        this.rootDocumentsRecords = Object.fromEntries(
          data.documents.flatMap((document) => (document.isRoot ? [[document.name, document]] : []))
        );
        this.tableDocumentsColumns = this.colorColumns([
          ...this.tableDocumentsColumns,
          ...Object.values(this.rootDocumentsRecords)
            .map((document) => ({
              title: document.name,
              section: parseSectionLabel(document.section),
              colored: false,
            }))
            .sort(this.sortSections),
        ]);
        this.tableDocumentsColumnsGroups = [...groupBy(this.tableDocumentsColumns, "section")].map(
          ([section, columns]) => ({
            section,
            count: columns.length,
          })
        );
        this.items = data.items;
        this.initTableWithItems(this.items);
      })
      .catch((err) => {
        console.error(err);
        this.toaster.error("Could not load compliance items");
      })
      .finally(() => {
        this.isLoadingItems = false;
      });
  };

  getStats = () => {
    this.compService
      .fetchStats()
      .then((data) => {
        this.stats = data;
        this.setCharts();
      })
      .catch((err) => {
        console.error(err);
        this.toaster.error("Could not load compliance stats");
      })
      .finally(() => {
        this.isLoadingStats = false;
      });
  };

  setCharts = () => {
    if (this.stats === undefined) {
      return;
    }

    this.charts.employmentStatus = this.stats.employment.map(({ status, caregiverIds }) => ({
      label: upperCaseFirst(status),
      value: caregiverIds.length,
      color:
        status === "Future status of on hold"
          ? PieChartColor.yellow
          : this.parseColor(getCaregiverStatusColor(status)),
    }));

    this.charts.complianceStatus = this.stats.caregivers.map(({ status, caregiverIds }) => ({
      label: upperCaseFirst(status),
      value: caregiverIds.length,
      color: getCaregiverOverviewComplianceStatusColor(status),
    }));

    this.initItemsComplianceStatusChartEntries();
  };

  setLocalStats = () => {
    this.localStats.pendingApplicationCaregivers = Object.values(this.caregiversMap)
      .filter((caregiver) => caregiver.status === "PENDING")
      .map((caregiver) => caregiver.id);
  };

  openCaregiverModal = (id: CaregiverId) =>
    this.$rootScope.openCaregiverModal(id, assertDefined(this.caregiversMap[id as unknown as number], "caregiver"));

  onDocumentSelect = (records: MultiselectCallbackParameter<CaregiverDocumentTypeId>) => {
    this.selectedDocumentTypeIds = new Set(records.map((r) => r.id));
    this.initItemsComplianceStatusChartEntries();
    this.onChartFilter("itemsComplianceStatus", [], false);
  };

  onCardClick = (card: CardName) => {
    const shouldFilter = !this.filterManager.hasFilter(card);

    this.onCardFilter(card, shouldFilter);
  };

  onRowClick = (caregiverId: CaregiverId) => {
    this.activeRowCaregiverId = this.activeRowCaregiverId === caregiverId ? undefined : caregiverId;
  };

  onCaregiverStatusSelect = (selected: MultiselectCallbackParameter<CaregiverStatus>) => {
    if (selected.length === 0) {
      this.filterManager.removeFilter("caregiverStatus");
      return;
    }

    const statuses = new Set(selected.map((r) => r.id));

    this.filterManager.addFilter("caregiverStatus", (record) =>
      statuses.has(record.caregiver.status)
    );
  };

  onComplianceStatusSelect = (
    selected: MultiselectCallbackParameter<CaregiverComplianceStatus>
  ) => {
    if (selected.length === 0) {
      this.filterManager.removeFilter("caregiverComplianceStatus");
      return;
    }

    const statuses = new Set(selected.map((r) => r.id));

    this.filterManager.addFilter("caregiverComplianceStatus", (record) =>
      statuses.has(record.caregiver.isCompliant ? "Compliant" : "Not compliant")
    );
  };

  onSearchChange = () => {
    const freeText = this.filters.freeText.trim();
    const regexp = new RegExp(freeText, "i");

    this.filterManager.addFilter("freeText", (item) => {
      return (
        item.caregiver.displayId === Number(freeText) || regexp.test(item.caregiver.displayName)
      );
    });
  };

  onClickSort = (columnTitle: string) => {
    const column = this.tableDocumentsColumns.find((c) => c.title === columnTitle);

    if (column === undefined) {
      console.error("Could not find column", columnTitle);
      return;
    }

    const sort = column.sort === "asc" ? "desc" : "asc";

    this.sortTable(column, sort);
  };

  onChartFilter = (chartName: ChartName, labels: ChartLabel[], needsDigest?: boolean) => {
    if (labels.length === 0) {
      this.filterManager.removeFilter(chartName);
      return;
    }

    let callback: (record: TableEntry) => boolean;

    switch (chartName) {
      case "employmentStatus": {
        const activeStatuses = new Set(
          [...labels]
            .filter((label) => !label.isHidden)
            .map((label) =>
              label.label === "Future status of on hold" ? label.label : label.label.toUpperCase()
            )
        );
        const caregiverIds = new Set(
          this.stats?.employment
            .filter((item) => activeStatuses.has(item.status))
            .map((item) => item.caregiverIds)
            .flat() ?? []
        );
        callback = (record) => caregiverIds.has(record.caregiver.id);
        break;
      }

      case "complianceStatus": {
        const activeStatuses = new Set(
          [...labels].filter((label) => !label.isHidden).map((label) => label.label)
        );
        const caregiverIds = new Set(
          this.stats?.caregivers
            .filter((item) => activeStatuses.has(item.status))
            .map((item) => item.caregiverIds)
            .flat() ?? []
        );
        callback = (record) => caregiverIds.has(record.caregiver.id);
        break;
      }

      case "itemsComplianceStatus": {
        const activeStatuses = new Set(
          [...labels].filter((label) => !label.isHidden).map((label) => label.label)
        );
        const caregiverIds = new Set(
          this.stats?.items
            .filter(
              (item) =>
                (this.selectedDocumentTypeIds.size === 0 ||
                  this.selectedDocumentTypeIds.has(item.documentTypeId)) &&
                activeStatuses.has(item.status)
            )
            .map((item) => item.caregiverIds)
            .flat() ?? []
        );
        callback = (record) => caregiverIds.has(record.caregiver.id);
        break;
      }

      default:
        assertNever(chartName);
    }

    this.filterManager.addFilter(chartName, callback);

    if (needsDigest !== false) {
      this.$scope.$digest();
    }
  };

  onCardFilter = (cardName: CardName, isActive: boolean) => {
    if (!isActive) {
      this.filterManager.removeFilter(cardName);
      return;
    }

    let caregiverIds: Set<CaregiverId>;

    switch (cardName) {
      case "pendingApplicationCaregivers":
        caregiverIds = new Set(
          Object.values(this.caregiversMap)
            .filter((caregiver) => caregiver.status === "PENDING")
            .map((caregiver) => caregiver.id)
        );
        break;

      case "incompliantCaregiversWithActiveCase":
        caregiverIds = new Set(this.stats?.incompliantCaregiversWithActiveCase ?? []);
        break;

      case "caregiversWithOnlyOneIncompliantItem":
        caregiverIds = new Set(this.stats?.caregiversWithOnlyOneIncompliantItem ?? []);
        break;

      case "incompliantItemsWithUploadedDocuments":
        caregiverIds = new Set(
          this.stats?.incompliantItemsWithUploadedDocuments.caregiverIds ?? []
        );
        break;

      default:
        assertNever(cardName);
    }

    this.filterManager.addFilter(cardName, (record) => caregiverIds.has(record.caregiver.id));
  };

  private initTableWithItems = (items: ComplianceCaregiverRecord[]) => {
    this.tableDataset = items.map(this.parseTableData);
    this.filterManager = new FilterManager(this.tableDataset, this.initTable);
    this.initTable();
  };

  private initTable = (dataset?: TableEntry[]) => {
    if (dataset !== undefined) {
      this.tableDataset = dataset;
    }

    this.table = new this.NgTableParams(
      { count: this.table.count() },
      { dataset: this.tableDataset }
    );
  };

  private onCaregiversLoaded = () => {
    this.isLoadingCaregivers = false;
    this.getItems();
    this.setLocalStats();
  };

  private fetchCaregivers = () => {
    if (Object.keys(this.caregiversMap).length !== 0) {
      return;
    }

    this.caregiversMap = this.DatabaseApi.caregivers();

    if (Object.keys(this.caregiversMap).length !== 0) {
      this.onCaregiversLoaded();
    }
  };

  private parseTableData = (record: ComplianceCaregiverRecord): TableEntry => {
    return {
      caregiver: {
        ...record.caregiver,
        complianceStatus: record.caregiver.isCompliant ? "Compliant" : "Not compliant",
        complianceStatusColor: record.caregiver.isCompliant ? "green" : "red",
      },
      items: Object.fromEntries([
        ...this.tableDocumentsColumns
          .filter((column) => column.section !== "Caregiver Information")
          .map((column): [string, ComplianceItemTableEntry] => [
            column.title,
            {
              status: "Not required",
              display: {
                text: "Not required",
                color: "gray",
              },
              documentType: assertDefined(
                this.rootDocumentsRecords[column.title],
                `this.rootDocumentsRecords[${column.title}]`
              ),
              dueDate: null,
              effectiveDate: null,
              expiryDate: null,
            },
          ]),
        ...record.items.map((item) => [
          item.documentType.name,
          {
            ...item,
            display: this.parseTableItemDisplayData(item),
          },
        ]),
      ]),
    };
  };

  private getCellData = (entry: TableEntry, column: TableColumn): string => {
    switch (column.title) {
      case "Caregiver Name":
        return entry.caregiver.displayName;

      case "ID":
        return `${entry.caregiver.displayId}`;

      case "Compliance":
        return entry.caregiver.complianceStatus;

      default:
        return entry.items[column.title].display.text;
    }
  };

  private sortTable = (sortColumn: TableColumn, sort?: "asc" | "desc") => {
    if (this.tableDataset === undefined) {
      return;
    }

    this.tableDocumentsColumns = [...this.tableDocumentsColumns].map((column) => ({
      ...column,
      sort: column.title === sortColumn.title ? sort : undefined,
    }));

    this.tableDataset.sort(
      (a, b) =>
        this.getCellData(a, sortColumn).localeCompare(this.getCellData(b, sortColumn)) *
        (sort === "asc" ? 1 : -1)
    );

    this.initTable();
  };

  private parseTableItemDisplayData(item: DashboardComplianceItem): {
    text: string;
    color: LabelColor;
  } {
    switch (item.status) {
      case "Not required":
        return {
          text: "Not required",
          color: "gray",
        };

      case "Not due yet":
        return {
          text:
            item.dueDate === null
              ? "Not due yet"
              : "Due in " + dateUtils.localDateToMDYString(item.dueDate),
          color:
            (item.dueDate?.compareTo(LocalDate.now().plusDays(30)) ?? 0) > 0 ? "gray" : "orange",
        };

      case "Resolved":
        return {
          text: "Resolved",
          color: "gray",
        };

      case "Missing":
        return {
          text: "Missing",
          color: this.getItemColorLabel(item),
        };

      case "Pending Uploads":
        return {
          text: "Pending Uploads",
          color: this.getItemColorLabel(item),
        };

      case "Compliant":
      case "Not Compliant":
        return {
          text: this.getDueDateText(item),
          color: this.getItemColorLabel(item),
        };

      default:
        assertNever(item.status);
    }
  }

  private getItemColorLabel(item: DashboardComplianceItem): LabelColor {
    if (item.status === "Pending Uploads") {
      return "orange";
    }

    if (item.status === "Compliant") {
      if ((item.expiryDate?.compareTo(LocalDate.now().plusDays(30)) ?? 0) < 0) {
        return "orange";
      }

      return "green";
    }

    return "red";
  }

  private getDueDateText(item: DashboardComplianceItem) {
    return [item.effectiveDate, item.expiryDate]
      .flatMap((date) =>
        date === null || date === undefined ? [] : [dateUtils.localDateToMDYString(date)]
      )
      .join(" - ");
  }

  private initItemsComplianceStatusChartEntries() {
    const items = sumItemsStatusGroups(
      groupBy(
        (this.stats?.items ?? [])
          .filter(
            (item) =>
              this.selectedDocumentTypeIds.size === 0 ||
              this.selectedDocumentTypeIds.has(item.documentTypeId)
          )
          .map(({ documentTypeId, status, caregiverIds }) => ({
            documentTypeId,
            status,
            totalCount: caregiverIds.length,
          })),
        "status"
      )
    );

    this.charts.itemsComplianceStatus = Array.from(items).map(([status, totalCount]) => ({
      label: upperCaseFirst(status),
      value: totalCount,
      color: getComplianceItemOverviewStatusColor(status),
    }));
  }

  private parseColor(color: LabelColor): PieChartColor {
    switch (color) {
      case "red":
        return PieChartColor.red;

      case "green":
        return PieChartColor.green;

      case "orange":
        return PieChartColor.orange;

      case "gray":
        return PieChartColor.gray;

      case "lightblue":
        return PieChartColor.blue;
    }
  }

  private colorColumns(columns: TableColumn[]): TableColumn[] {
    let colored = false;

    return columns.map((column, i) => {
      colored = i === 0 || columns[i - 1].section === column.section ? colored : !colored;

      return {
        ...column,
        colored,
      };
    });
  }

  private sortSections = (a: TableColumn, b: TableColumn): number => {
    const defaultSections = ["General", "Medical", "I9"];

    if (defaultSections.includes(a.section) && !defaultSections.includes(b.section)) {
      return -1;
    }

    if (!defaultSections.includes(a.section) && defaultSections.includes(b.section)) {
      return 1;
    }

    return a.section.localeCompare(b.section);
  };
}

interface Component extends angular.IComponentOptions {
  $name: string;
}

export const complianceDashboardComponent: Component = {
  $name: "complianceDashboard",
  templateUrl: "admin/components/compliance-dashboard/compliance-dashboard.component.html",
  controller: ComplianceDashboardCtrl,
  controllerAs: "ctrl",
};
