import angular from "angular";
import { Chart, registerables } from "chart.js";
import { AppModule } from "./app.module";

Chart.register(...registerables);

if (import.meta.env.PROD) {
  import("./admin/scripts/utils/clarity").then(({ default: register }) => register());
}

angular.element(() => angular.bootstrap(document, [AppModule], { strictDi: true }));
